import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";
import CartProvider from "./context/addToCart";
import { Provider } from "react-redux";
import { store } from "./app/store";
import ArraryProvider from "./context/iconsContext";
import { ConfigProvider } from "antd";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <CartProvider>
        <ArraryProvider>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary:"#000",
                colorPrimaryActive	:"#808080"
              },
            }}
          >
            <Router>
              <App />
            </Router>
          </ConfigProvider>
        </ArraryProvider>
      </CartProvider>
    </Provider>
  </React.StrictMode>
);

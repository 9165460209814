import React, { useState } from "react";
import axios from "axios";
import "../../styles/account.css";
import SignUpImg from "../../assets/images/SignUp.gif";
import { UserOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Checkbox, Form, Input, message } from "antd";
import { MdOutlineEmail } from "react-icons/md";
import { BsTelephone } from "react-icons/bs";
import { BiLockOpen } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserToken } from "../../features/authSlice";
import { setProfile, storeToken } from "../../services/localStorageServices";

export default function SignUp() {
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = React.useRef(null);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("action", "add");
      formData.append("usertype", "user");
      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("phone", phoneNumber);
      formData.append("password", values.password);
      const response = await axios.post(
        "https://indiandelightms.com/api/user-detail.php",
        formData
      );

      if (response.data) {
        if (response.data.status === "Failed") {
          message.error("email and password is wrong ");
        } else {
          dispatch(setUserToken({ token: response.data.token }));
          storeToken(response.data.token);
          setProfile(response.data.profile);
          navigate("/");
        }
      }
      // Handle success, redirect, or show a success message to the user.
    } catch (error) {
      console.error("Signup Error", error);
      // Handle errors, display an error message, or take appropriate action.
    } finally {
      setLoading(false);
    }
  };

  // ================= Phone Number Input ====================

  const formatPhoneNumber = (input) => {
    // Remove non-numeric characters from the input
    const numericInput = input.replace(/\D/g, "");

    // Apply phone number formatting (adjust as needed)
    const formattedNumber = numericInput.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );

    return formattedNumber;
  };

  const handleChange = (e) => {
    const input = e.target.value;
    const formattedNumber = formatPhoneNumber(input);
    formRef.current?.setFieldsValue({
      phone: formattedNumber,
    });
    setPhoneNumber(formattedNumber);
  };

  return (
    <div className="row" style={{ backgroundColor: "white" }}>
      <div className="col-md-6 px-0 px-md-3 px-lg-5 d-flex align-items-center">
        <div className="account-card signUp">
          <div className="title">
            <h1>Sign Up</h1>
            <Breadcrumb
              items={[
                {
                  title: <Link to="/">Home</Link>,
                },
                {
                  title: "Sign Up",
                },
              ]}
            />
          </div>
          <div className="account-form">
            <Form
              name="normal_login"
              className="login-form"
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              ref={formRef}
            >
              <div className="row">
                <div className="col-12">
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your name!",
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Full Name"
                    />
                  </Form.Item>
                </div>
                <div className="col-md-6">
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your email!",
                      },
                      {
                        type: "email",
                        message: "Please enter a valid email!",
                      },
                    ]}
                  >
                    <Input
                      prefix={
                        <MdOutlineEmail className="site-form-item-icon" />
                      }
                      placeholder="Email"
                    />
                  </Form.Item>
                </div>
                <div className="col-md-6">
                  <Form.Item
                    name="phone"
                    label="Phone"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your phone no.!",
                      },
                      {
                        min: 10,
                        max: 15,
                        message: "Please enter a valid Phone!",
                      },
                    ]}
                  >
                    <Input
                      onChange={handleChange}
                      prefix={"+1"}
                      placeholder="Phone No."
                    />
                  </Form.Item>
                </div>
                <div className="col-md-6">
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      prefix={<BiLockOpen className="site-form-item-icon" />}
                      placeholder="Password"
                    />
                  </Form.Item>
                </div>
                <div className="col-md-6">
                  <Form.Item
                    label="Confirm Password"
                    name="password2"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The new password that you entered does not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      prefix={<BiLockOpen className="site-form-item-icon" />}
                      placeholder="Confirm Password"
                    />
                  </Form.Item>
                </div>

                <Form.Item>
                  <Button
                    htmlType="submit"
                    className="login-form-button"
                    loading={loading}
                  >
                    {loading ? "Signing up..." : "Sign Up"}
                  </Button>
                  <br></br>
                  Or <Link to="/account/sign-in">login now!</Link>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div className="col-md-6 d-flex align-items-center d-none d-md-flex">
        <img className="widthimage" src={SignUpImg} alt="login"></img>
      </div>
    </div>
  );
}

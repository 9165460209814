import React from "react";
import { useCartContext } from "../../context/addToCart";
import { BsTrashFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Avatar, Button, Drawer, InputNumber, List } from "antd";
import EmptyComp from "../Empty/empty";

export default function Cart() {
  const {
    handleCartList,
    showCartList,
    cartValue,
    setCartValue,
    setCartCount,
    setShowCartList,
    selectedBranch
  } = useCartContext();

  const navigate = useNavigate();
  const handlePlaceOrder = () => {
    navigate("/place-order");
    setShowCartList(false);
  };

  // ====================== Increase Quantity ====================

  const handleQuantityUpdate = (value, index) => {
    const prevData = cartValue;
    prevData[index].itemQty = parseInt(value);
    prevData[index].price = cartValue[index].fixedPrice * value;
    setCartValue([...prevData]);
    addtoLocatStorage(prevData);
  };

  // ================= Remove Cart Data ===================

  const removeCartData = (index) => {
    const prevData = cartValue;

    prevData.splice(index, 1);
    setCartValue([...prevData]);
    addtoLocatStorage(prevData);

    setCartCount(prevData.length);
  };

  // =================== add to local Storage ==================

  const addtoLocatStorage = (data) => {
    localStorage.setItem("cart", JSON.stringify(data));
  };

  return (
    <div>
      <Drawer
        title="Cart"
        placement="right"
        onClose={handleCartList}
        open={showCartList}
      >
        {cartValue && cartValue.length > 0 ? (
          <>
            <List itemLayout="horizontal">
              {cartValue.map((value, index) => (
                <List.Item key={index}>
                  <List.Item.Meta
                    avatar={<Avatar src={value.Image} />}
                    title={
                      <div className="d-flex justify-content-between">
                        <span>
                          {value.Name} {value.spice && `(${value.spice} spicy)`}
                        </span>
                        <span
                          onClick={() => removeCartData(index)}
                          style={{ color: "red" }}
                        >
                          <BsTrashFill />
                        </span>
                      </div>
                    }
                    description={
                      <div className="d-flex justify-content-start align-items-center">
                        <span>Price:&nbsp;$&nbsp;{value.price} </span>
                        &nbsp;&nbsp;&nbsp;
                        <span>
                          Qty:
                          <InputNumber
                            min={1}
                            max={10}
                            type="number"
                            onChange={(value) =>
                              handleQuantityUpdate(value, index)
                            }
                            style={{ width: 65 }}
                            value={value.itemQty}
                            placeholder="Qty"
                          />
                        </span>
                      </div>
                    }
                  />
                </List.Item>
              ))}
            </List>
            <div
              className="bottomButton"
              style={{
                bottom: 0,
                left: 0,
                position: "sticky",
                background: "#fff",
                width: "100%",
                padding: "15px",
              }}
            >
              <Button
              disabled={selectedBranch && (selectedBranch.is_closed === "0" || selectedBranch.is_closed === 0)}
                style={{
                  width: "100%",
                  background: "green",
                  color: "#fff",
                  height: "40px",
                  borderRadius: "50px",
                }}
                onClick={handlePlaceOrder}
              >
                Proceed to checkout
              </Button>
              {selectedBranch && (selectedBranch.is_closed === "0" || selectedBranch.is_closed === 0)  && <span style={{color:"red", width:"100%", textAlign:"center"}}>Booking has been closed</span>}
            </div>
          </>
        ) : <EmptyComp message="No data in cart" />}
      </Drawer>
    </div>
  );
}

import "../styles/aboutUs.css";
import TestimonialSecction from "../components/testimonial/TestimonialSecction";
import WhyChoose from "../components/about/whyChoose";
// import Team from "../components/about/team";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import aboutUs  from "../assets/images/abbou.jpg";
import { useEffect } from "react";

function AboutUs() {
  useEffect(() => {
    // Scroll to the top of the page on component mount
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <div className="aboutUs-container padding-top">
        <div className="aboutUs-upper-section">
          {/* <img src={img} alt="" className="aboutUs-upper-img" /> */}
          <div className="container about-upper-text">
            <h1>
              Indian Delight - Your One Stop for Authentic Indian Flavours
            </h1>
            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
            {/* <Link to= "/menu" ><button>Order Now</button></Link> */}
          </div>
        </div>
        <div className="about-us-container section" id="about">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="about-us">
                  <h2>GET TO KNOW US</h2>
                  <h4>
                    Indian Delight: Elevating Palates with Exquisite
                    Authenticity and Culinary Mastery
                  </h4>
                  <p>
                    ̦ Indian Delight: Your One-Stop for Authentic Indian Flavors!
                    Experience the true essence of India at our Hattiesburg
                    location, with additional branches in Ocean Spring. Immerse
                    yourself in the rich tapestry of Indian cuisine with us. Our
                    Hattiesburg branch boasts comfortable seating for 125,
                    ensuring a delightful dining experience. Discover the
                    diverse and authentic flavors that define Indian culinary
                    excellence. Visit us and savor the taste, tradition, and
                    warmth at Indian Delight.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="about-us-img-container">
                  <div className="empty-box"></div>
                  <img src={aboutUs} alt="about us" style={{maxWidth:"500px"}} className="about-us-img" />
                </div>
              </div>
            </div>
            <WhyChoose />
          </div>
        </div>
        {/* <Team /> */}
        <TestimonialSecction />
      </div>
      <Footer />
    </>
  );
}

export default AboutUs;

import React from "react";

export default function PrivacyPolicy() {
  return (
    <div>
      <h1>Privacy Policy</h1>

      <p>
        <strong>Introduction</strong>
        <br />
        GK Hospitality ("we," "our," or "us") has developed this Task Management
        application as a commercial app. This Privacy Policy outlines how we
        collect, use, and disclose personal information from users who choose to
        use our service. By using our service, you agree to the collection and
        use of information in accordance with this policy.
      </p>

      <p>
        <strong>Information Collection and Use</strong>
        <br />
        To enhance your experience with our Task Management application, we may
        ask you to provide us with certain personally identifiable information,
        including but not limited to your name, email address, device details,
        and any notes or events you create within the app. The information we
        collect will be used to provide and improve our service and will be
        retained by us as described in this Privacy Policy. We do not share your
        information with any third parties, except as specified in this policy.
      </p>

      <p>
        <strong>Log Data</strong>
        <br />
        In the event of an error while using our service, we may collect data
        and information on your device, known as Log Data. This Log Data may
        include your device’s Internet Protocol ("IP") address, device name,
        operating system version, app configuration, the time and date of your
        use, and other statistics. This data helps us diagnose and fix issues,
        as well as improve the app’s performance.
      </p>

      <p>
        <strong>Cookies</strong>
        <br />
        Our app does not use cookies directly. However, third-party services
        integrated into our app may use cookies to collect information and
        improve their services. You can choose to accept or refuse these cookies
        through your device settings. Refusing cookies may affect the
        availability of certain features of our service.
      </p>

      <p>
        <strong>Service Providers</strong>
        <br />
        We may employ third-party companies and individuals to facilitate our
        service, provide the service on our behalf, perform service-related
        tasks, or assist in analyzing how our service is used. These third
        parties may have access to your personal information but are obligated
        not to disclose or use it for any other purpose.
      </p>

      <p>
        <strong>Security</strong>
        <br />
        We are committed to protecting your personal information and use
        commercially acceptable measures to ensure its security. However, no
        method of transmission over the internet or electronic storage is 100%
        secure, and we cannot guarantee absolute security.
      </p>

      <p>
        <strong>Links to Other Sites</strong>
        <br />
        Our service may include links to external sites not operated by us. We
        are not responsible for the content, privacy policies, or practices of
        these external sites. We recommend reviewing the privacy policy of any
        third-party site you visit.
      </p>

      <p>
        <strong>Children’s Privacy</strong>
        <br />
        Our service is not intended for children under the age of 13. We do not
        knowingly collect personal information from children under 13. If we
        learn that we have collected personal information from a child under 13
        without parental consent, we will take steps to delete that information.
        If you are a parent or guardian and believe that your child has provided
        us with personal information, please contact us.
      </p>

      <p>
        <strong>Changes to This Privacy Policy</strong>
        <br />
        We may update our Privacy Policy from time to time. Any changes will be
        posted on this page, and you are advised to review this policy
        periodically. The changes are effective immediately upon posting.
      </p>

      <p>
        <strong>Contact Us</strong>
        <br />
        If you have any questions or concerns about this Privacy Policy, please
        contact us at [Your Contact Information].
      </p>
    </div>
  );
}

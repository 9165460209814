import { Card, Pagination, Table } from "antd";
import Column from "antd/es/table/Column";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Search from "antd/es/input/Search";
import { getProfile } from "../../services/localStorageServices";
import DrawerOrders from "./DrawerOrders";

export default function OrderListComp() {
  const [data, setData] = useState([]);
  const { profile } = getProfile();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(null);

  //   ================== Fetch Data ===============

  const fetchData = async (id, pageProp, limitProp, search) => {
    try {
      setLoading(true);
      const response = await axios.post(
        "https://indiandelightms.com/api/order-detail.php?action=fetchUser&user=" +
          id +
          "&page=" +
          pageProp +
          "&limit=" +
          limitProp +
          "&search=" +
          search +
          ""
        // If there are any parameters to be sent in the request body, include them here
        // For example: { key1: 'value1', key2: 'value2' }
      );

      if (response.data) {
        setLoading(false);
        // Assuming the API response contains the data you want
        setData(response.data.list ? response.data.list : []);
        setTotal(response.data.total);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData(profile.id, page, limit, "");
    // eslint-disable-next-line
  }, [limit, page]); // Empty dependency array ensures the effect runs only once

  const handlePageChange = (pageData, pageSizeData) => {
    setPage(pageData);
    setLimit(pageSizeData);
  };

  return (
    <section className="OrderList-Section">
      <div className="container-fluid">
        <div className="OderList-Head d-md-flex justify-content-between">
          <h2>Order List</h2>
          <Search
            placeholder="input search text"
            allowClear
            onSearch={(value) => fetchData(profile.id, page, limit, value)}
            style={{ maxWidth: "300px", marginBottom: "10px" }}
          />
        </div>
      </div>
      <div className="OrderList-main-con" id="style-1">
        <div className="OrderList-head-body  title-orderlist">
          <Table dataSource={data} pagination={false} loading={loading}>
            <Column title="Order ID" dataIndex="Order_id" key="Order_id" />

            <Column title="Branch" dataIndex="branchName" key="branchName" />
            <Column
              title="Total Food"
              dataIndex="food_total"
              key="food_total"
              render={(text) => <strong>${text}</strong>}
            />
            <Column
              title="Tip"
              dataIndex="tip"
              key="tip"
              render={(text) => <strong>${text}</strong>}
            />
            <Column
              title="Total"
              dataIndex="total_amount"
              key="total_amount"
              render={(text) => <strong>${text}</strong>}
            />
            <Column
              title="Pick Up Note"
              dataIndex="pickup_note"
              key="pickup_note"
              render={(text) => <strong>{text}</strong>}
            />
            <Column
              title="Pick Up Time"
              dataIndex="pickup_time"
              key="pickup_time"
              render={(text) => <strong>{text}</strong>}
            />
            <Column
              title="Booking"
              dataIndex="status"
              key="status"
              render={(text, record) => (
                <span
                  style={{
                    color:
                      text === "success"
                        ? "green"
                        : text === "failed"
                        ? "red"
                        : "orange",
                  }}
                >
                  {text}
                </span>
              )}
            />

            <Column
              title="Details"
              key="details"
              render={(text, record) => <DrawerOrders detaileView={record} />}
            />
          </Table>
        </div>

        {data.map((elem) => {
          const {
            Order_id,
            branchName,
            food_total,
            pickup_time,
            tip,
            total_amount,
            status,
            pickup_note,
          } = elem;
          return (
            <div
              className="card-detail"
              style={{ paddingTop: "10px" }}
              key={Order_id}
            >
              <Card>
                <div className="row">
                  <div className="col-md-4">
                    <span style={{ fontWeight: "700" }}>Order Id:</span> {Order_id}
                  </div>
                  <div className="col-md-8">
                    <span style={{ fontWeight: "700" }}>Branch:</span>{" "}
                    {branchName}
                  </div>
                  <div className="col-md-4">
                    <span style={{ fontWeight: "700" }}>Total Food:</span> $
                    {food_total}
                  </div>
                  <div className="col-md-4">
                    <span style={{ fontWeight: "700" }}>Tip:</span>{" "}
                    <span>${tip}</span>
                  </div>
                  <div className="col-md-4">
                    <span style={{ fontWeight: "700" }}>Total:</span> $
                    {total_amount}
                  </div>
                  <div className="col-md-4">
                    <span style={{ fontWeight: "700" }}>Pickup Note:</span>{" "}
                    {pickup_note}
                  </div>
                  <div className="col-md-4">
                    <span style={{ fontWeight: "700" }}>Pickup Time:</span>{" "}
                    {pickup_time}
                  </div>
                  <div className="col-md-4">
                    <span style={{ fontWeight: "700" }}>Booking:</span> {status}
                  </div>

                  <div className="col-md-4">
                    <div className="OrderListData-table-head">
                      <DrawerOrders detaileView={elem} />
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          );
        })}

        <Pagination
          style={{ marginTop: "30px" }}
          total={total}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          pageSize={limit}
          current={page}
          onChange={handlePageChange}
        />
      </div>
    </section>
  );
}

import "./App.css";
import AboutUs from "./pages/AboutUs";
import Home from "./pages/Home";
import { Route, Routes } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Foods from "./pages/Foods";
import Account from "./pages/Account";
import Preloader from "./pages/Preloader";
import { useState } from "react";
import Cart from "./components/Cart/Cart";
import ContactUsPage from "./pages/ContactUs";
import PlaceOrder from "./pages/PlaceOrder";
import DeliveryPartner from "./pages/DeliveryPartner";
import Menu from "./pages/Menu";
import OrderList from "./pages/OrderList";
import { useCartContext } from "./context/addToCart";
import SuccessResponse from "./pages/succesResponse";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
  const { loader } = useCartContext();

  return (
    <>
      {loader ? <Preloader /> : null}
      <Cart />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/foods/:branch" element={<Foods />} />
        <Route path="/account/:page" element={<Account />} />
        <Route path="/place-order" element={<PlaceOrder />} />
        <Route
          path="/delivery-partners/:branch"
          element={<DeliveryPartner />}
        />
        <Route path="/menu/:branch" element={<Menu />} />
        <Route path="/user/:active" element={<OrderList />} />
        <Route path="/booking-success/:orderId" element={<SuccessResponse />} />
        <Route path="/booking-failed/:orderId" element={<SuccessResponse />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </>
  );
}

export default App;

import React, { createContext, useContext } from "react";
import { AiFillFacebook, AiFillTwitterCircle, AiFillYoutube } from "react-icons/ai";
import { FaSquareInstagram, FaSquareThreads } from "react-icons/fa6";
import { FaYelp } from "react-icons/fa";
import {FcGoogle} from "react-icons/fc";

const ArrayContext = createContext();

export const useArrayContext = () => useContext(ArrayContext);

const ArraryProvider = ({ children }) => {
    const iconsList = [
        {
          name: "facebook",
          icon: <AiFillFacebook />,
        },
        {
          name: "instagram",
          icon: <FaSquareInstagram />,
        },
        {
          name: "youtube",
          icon: <AiFillYoutube />,
        },
        {
          name: "twitter",
          icon: <AiFillTwitterCircle />,
        },
        {
          name: "google my business",
          icon: <FcGoogle />,
        },
        {
          name: "thread",
          icon: <FaSquareThreads />,
        },
        {
          name: "yelp",
          icon: <FaYelp />,
        }
      ]

      
  const contextValue = {
    iconsList,
  };
  return (
    <ArrayContext.Provider value={contextValue}>
      {children}
    </ArrayContext.Provider>
  );
};

export default ArraryProvider;

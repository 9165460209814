import "../styles/catalog.css";
import { Badge, Col, Row, Skeleton, Spin, Tabs } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import React, { useEffect, useState } from "react";
import FoodCard from "../components/food/FoodCard";
import { Sticky, StickyContainer } from "react-sticky";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { useCartContext } from "../context/addToCart";
import { useParams } from "react-router-dom";
import { Input } from "antd";
import axios from "axios";
function Foods() {
  const [selectedTab, setSelectTab] = useState("appetiser");
  const [headerHeight, setHeaderHeight] = useState(null);
  const [FoodList, setFoodList] = useState(null);
  const [loader, setLoader] = useState(false);
  const [foodData, setFoodData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [branchId, setBranchId] = useState(null);
  const [loading, setLoading] = useState(false);
  console.log(foodData);
  const { branchData, cartCount, handleCartList, setSelectedBranch } =
    useCartContext();
  const { branch } = useParams();

  const handleChange = (key) => {
    setSelectTab(key);

    const targetSection = document.getElementById(key);

    if (targetSection) {
      const elementTop =
        targetSection.getBoundingClientRect().top + window.scrollY;
      const scrollPosition = elementTop - headerHeight;

      // Scroll to the target section using smooth scrolling
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    } else {
      console.log("not found");
    }
  };

  useEffect(() => {
    const divElement = document.getElementById("menuList"); // Replace 'yourDivId' with the actual ID of your div element

    if (divElement) {
      const divHeight = divElement.offsetHeight;
      setHeaderHeight(divHeight + 80);
    }
  }, []);

  const fetchFoodList = (branchIdData) => {
    setLoader(true);
    fetch(
      `https://indiandelightms.com/api/catogery-details.php?page=1&limit=20&action=fetchAll&branch=${branchIdData}`
    )
      .then((response) => response.json())
      .then((data) => {
        setFoodData([]);
        setSearchTerm("");
        setFoodList(data.list);
        console.log(data.list);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching branch data:", error);
      });
  };

  useEffect(() => {
    const filterData = branchData.filter(
      (value) => value.branch_slug === branch
    );
    if (filterData && filterData.length > 0) {
      fetchFoodList(filterData[0].id);
      setSelectedBranch(filterData[0]);
      setBranchId(filterData[0].id);
      sessionStorage.setItem("branch", JSON.stringify(filterData[0]));
    }
  }, [branch, branchData]);

  const hidePopup = [
    "salad",
    "breads",
    "dessert",
    "sides",
    "Dessert",
    "Breads",
    "Dessert",
    "Sides",
    "Salad",
    "Appetizers",
    "appetizers",
    "desserts",
    "Desserts",
    "salads",
    "Salads",
  ];
  useEffect(() => {
    // Scroll to the top of the page on component mount
    window.scrollTo(0, 0);
  }, []);
  ///food serch ////
  const handleSearch = (value) => {
    setSearchTerm(value);
  };
  const fetchData = async (search) => {
    try {
      setLoading(true);
      let limit;
      if (search !== "") {
        limit = 10;
      } else {
        limit = 0;
      }
      const response = await axios.post(
        `https://indiandelightms.com/api/food-details.php?page=1&limit=${limit}&action=fetchSearch&search=${search}&branch=${branchId}`
      );

      if (response.data) {
        setLoading(false);
        setFoodData(response.data.list);
      }
    } catch (error) {
      console.error("Error fetching data:", error);

      // Handle specific error cases if needed
      if (axios.isCancel(error)) {
        console.error("Request canceled:", error.message);
      } else if (axios.isAxiosError(error)) {
        // Handle Axios-specific errors
        console.error("Axios error:", error.response);
      } else {
        // Handle other types of errors
        console.error("Unknown error:", error);
      }
    }
  };

  useEffect(() => {
    const searchTime = setTimeout(() => {
      fetchData(searchTerm);
    }, 500);

    return () => {
      clearTimeout(searchTime);
    };
  }, [searchTerm]);
  /////

  return (
    <>
      <StickyContainer>
        <Navbar hideScroll="hideScroll" />
        <Sticky topOffset={80}>
          {({ style }) => (
            <div style={style} className="menu-list" id="menuList">
              {branchData
                .filter((item) => item.branch_slug === branch)
                .map((branchItem) => (
                  <div className="container mb-3 " key={branchItem.id}>
                    <div className="row">
                      <div className="headTitle maintext col-md-8 col-12">
                        <div className="textflex  ">
                          <div className="flex">
                            <img
                              src="https://lh3.googleusercontent.com/p/AF1QipPyX8KnIovIC3LFtoJaRzdkNiobTzT03PIXBqGC=s1360-w1360-h1020"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="textflex">
                          <h2 className="fontsizeone">{branchItem.name}</h2>
                          <p className="fontsizepera" style={{ margin: 0 }}>
                            {branchItem.address}
                          </p>
                          {(branchItem.is_closed === "0" || branchItem.is_closed === 0) ? (
                            <p style={{ color: "red" }}>
                              Booking has been closed
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-2 col-6">
                        <Input
                          placeholder="Search for food...  "
                          size="large"
                          style={{ border: "0.5px solid black" }}
                          prefix={<SearchOutlined style={{ marginRight: 8 }} />}
                          onChange={(e) => handleSearch(e.target.value)}
                        />
                      </div>
                      <div className="leftPanel textflex col-md-2 col-6 d-flex justify-content-end">
                        <Badge count={cartCount}>
                          <div
                            className="cart-icon-section"
                            onClick={handleCartList}
                          >
                            <FontAwesomeIcon
                              icon={faShoppingCart}
                              className="cart-icon"
                            />
                            Your Cart
                          </div>
                        </Badge>
                      </div>
                    </div>
                  </div>
                ))}
              <div className="container">
                <Tabs
                  defaultActiveKey={selectedTab}
                  tabPosition="top"
                  onTabClick={handleChange}
                  items={
                    !loader &&
                    FoodList &&
                    FoodList.map((value, i) => {
                      return {
                        label: (
                          <span
                            className="menuItem"
                            style={{ textTransform: "capitalize" }}
                          >
                            {value.Name}
                          </span>
                        ),
                        key: value.slug,
                        children: null,
                      };
                    })
                  }
                />
              </div>
            </div>
          )}
        </Sticky>

        {loader && (
          <div className="container mb-5">
            <div className="row">
              {[...Array(12)].map((_, index) => (
                <div className="col-xl-3 col-lg-4 col-sm-6 col-12 mb-3">
                  <div className="food-card-container">
                    <Row>
                      <Col span={8}>
                        <Skeleton.Image active={true} />
                      </Col>
                      <Col span={2}></Col>
                      <Col span={12}>
                        <Skeleton.Input active={true} size="default" />
                        <br />
                        <br />
                        <Skeleton.Avatar
                          active={true}
                          size={"default"}
                          shape={"square"}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Skeleton.Button
                          active={true}
                          size={"default"}
                          shape={"square"}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* {!loader &&
          FoodList &&
          FoodList.map(
            (category) =>
              category.foods.length > 0 && (
                <div
                  className="food-section"
                  id={category.slug}
                  key={category.slug}
                >
                  <div className="container ">
                    <h4 style={{ textTransform: "capitalize" }}>
                      {category.Name}
                    </h4>
                    <div className="foodList justify-content-center">
                      <div className="row">
                        {category.foods.map((foodItem, i) => (
                          <div
                            className="col-xl-3 col-lg-4 col-md-6 col-12"
                            key={i}
                          >
                            <FoodCard
                              isShowPopup={hidePopup.includes(category.Name)}
                              value={foodItem}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )
          )}

        <div>
        <div
                  className="food-section"
                 
                >
                  <div className="container ">
                    <h4 style={{ textTransform: "capitalize" }}>
                  
                    </h4>
                    <div className="foodList justify-content-center">
                      <div className="row">
          {foodData.map((foodData, i) => (
            <div className="col-xl-3 col-lg-4 col-md-6 col-12" key={i}>
              <FoodCard value={foodData} />
            </div>
          ))}
           </div>
                    </div>
                  </div>
                </div>
        </div> */}

        {/* Existing code... */}
        <Spin spinning={loading}>
          {foodData.length > 0 && (
            <div className="food-section">
              <div className="container ">
                <h4 style={{ textTransform: "capitalize" }}></h4>
                <div className="foodList justify-content-center">
                  <div className="row">
                    {foodData.map((foodItem, i) => (
                      <div
                        className="col-xl-3 col-lg-4 col-md-6 col-12"
                        key={i}
                      >
                        <FoodCard value={foodItem} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          {foodData.length === 0 && // Add this condition to hide FoodList when foodData.length is greater than 0
            !loader &&
            FoodList &&
            FoodList.map(
              (category) =>
                category.foods.length > 0 && (
                  <div
                    className="food-section"
                    id={category.slug}
                    key={category.slug}
                  >
                    <div className="container ">
                      <h4 style={{ textTransform: "capitalize" }}>
                        {category.Name}
                      </h4>
                      <div className="foodList justify-content-center">
                        <div className="row">
                          {category.foods.map((foodItem, i) => (
                            <div
                              className="col-xl-3 col-lg-4 col-md-6 col-12"
                              key={i}
                            >
                              <FoodCard
                                isShowPopup={hidePopup.includes(category.Name)}
                                value={foodItem}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )
            )}
        </Spin>

        <Footer />
      </StickyContainer>
    </>
  );
}

export default Foods;

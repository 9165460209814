import React, { useEffect, useState } from "react";
import "../styles/OrderList.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { BiSolidUserCircle } from "react-icons/bi";
import { IoListCircleSharp } from "react-icons/io5";

import { useParams } from "react-router-dom";
import ProfileDetails from "../components/orderDetails/ProfileDetails";
import OrderListComp from "../components/orderDetails/OrderListComp";
const OrderList = () => {
  const { active } = useParams();
  const [showOrderList, setShowOrderList] = useState(false); // Initially hide Order List

  useEffect(() => {
    setShowOrderList(active === "order");
  }, [active]);

  useEffect(() => {
    // Scroll to the top of the page on component mount
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />

      <section className="OrderList-Section-1">
        <div className="container">
          <div className="OrderList-Section-1-con">
            <div className="row">
              <div className="col-lg-2 col-md-3 col-12 mb-3">
                <div className="OrderList-card-container">
                  <div className="OrderList-Card-Body">
                    <div
                      className={
                        !showOrderList
                          ? `OrderList-Card-Body-icons-con activeProfile`
                          : `OrderList-Card-Body-icons-con `
                      }
                      onClick={() => setShowOrderList(false)}
                    >
                      <div className="OrderList-Card-Body-icons">
                        <BiSolidUserCircle />
                      </div>
                      <h2>Profiles</h2>
                    </div>
                    <div
                      className={
                        showOrderList
                          ? `OrderList-Card-Body-icons-con activeProfile`
                          : `OrderList-Card-Body-icons-con `
                      }
                      onClick={() => setShowOrderList(true)}
                    >
                      <div className="OrderList-Card-Body-icons">
                        <IoListCircleSharp />
                      </div>
                      <h2>Order List</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-10 col-md-9 col-12">
                {showOrderList ? <OrderListComp /> : <ProfileDetails />}
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default OrderList;

import React, { useEffect, useState } from "react";
import { Button, Divider, Result, Col, Row, List, Avatar } from "antd";
import { BiSolidPhoneCall } from "react-icons/bi";
import { FaLocationDot } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const SuccessResponse = () => {
  const { orderId } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const navigate = useNavigate();

  const fetchOrderDetails = async (id) => {
    const res = await axios.get(
      "https://indiandelightms.com/api/order-detail.php?action=fetch&limit=1&page=1&search=" +
        id +
        ""
    );

    if (res.data) {
      setOrderDetails(res.data.list[0]);
    }
  };

  useEffect(() => {
    fetchOrderDetails(orderId);
  }, [orderId]);

  return (
    orderDetails && (
      <Result
        status="success"
        title="Successfully Place your orders!"
        subTitle={`Order number:${orderDetails.Order_id} Order will take for ready to pickup at ${orderDetails.pickup_time}, please wait.`}
        style={{ maxWidth: "600px", margin: "auto" }}
      >
        <Divider orientation="left">Pickup Details</Divider>
        <div className="personalDetails">
          <h6>{orderDetails.branchName}</h6>
          <div className="contact-detail">
            <p style={{ margin: "unset" }}>
              <BiSolidPhoneCall />
              +1&nbsp;{orderDetails.branchPhone}
            </p>
          </div>
          <div className="contact-address d-flex align-items-start">
            <FaLocationDot />
            &nbsp;
            <p style={{ margin: "unset" }}>{orderDetails.branchAddress}</p>
          </div>
        </div>
        <Divider orientation="left">Order Details</Divider>
        <div className="cartList">
          <List style={{ maxHeight: "300px", overflow:"auto" }} itemLayout="horizontal">
            {orderDetails && orderDetails.foods.map((value, index) => (
              <List.Item key={index}>
                <List.Item.Meta
                  avatar={<Avatar src={value.Image} />}
                  title={
                    <Row>
                      <Col span={16}>
                        <span>
                          {value.Name}(
                          {value.foodType === "nonVeg" ? "Non Veg" : "Veg"})
                          <br />{value.food_spice ? `(${value.food_spice}&nbsp;spicy)` : null}
                        </span>
                      </Col>
                      <Col span={4}>
                        <span style={{ color: "#808080" }}>
                          Qty:&nbsp;{value.food_qty}
                        </span>
                      </Col>
                      <Col span={4}>
                        <span style={{ color: "#808080" }}>
                          $&nbsp;{value.price}{" "}
                        </span>
                      </Col>
                    </Row>
                  }
                />
              </List.Item>
            ))}
          </List>
        </div>
        <Divider orientation="left">Bill Details</Divider>
        <div className="billDetails mb-4">
          <div className="listRow">
            <strong>Subtotal</strong>
            <span>$ {orderDetails.food_total}</span>
          </div>
          <div className="listRow">
            <strong>Sales Tax 10%</strong>
            <span>$ {orderDetails.sales_tax}</span>
          </div>

          <div className="listRow">
            <strong>Tip</strong>
            <span>$ {orderDetails.tip}</span>
          </div>
          <Divider />
          <div className="listRow">
            <strong>Total Pay</strong>
            <span>$ {orderDetails.total_amount}</span>
          </div>
        </div>
        <Button onClick={()=>navigate("/")} style={{background:"#000", color:"#fff"}}>Back To Home</Button>
      </Result>
    )
  );
};
export default SuccessResponse;

import React, { createContext, useContext, useState } from "react";
import { Avatar, List, notification } from "antd";

const CartContext = createContext();

export const useCartContext = () => useContext(CartContext);

const CartProvider = ({ children }) => {
  const [cartValue, setCartValue] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [showCartList, setShowCartList] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [branchData, setBranchData] = useState([]);
  const [branchDetail, setBranchDetail] = useState(null);
  const [pickupNote, setPickupNote] = useState(null);
  const [DeliveryTime, setDeliveryTime] = useState(null);
  const [tipPrice, setTipPrice] = useState(0);
  const handleCartList = () => setShowCartList((prev) => !prev);
  const [loader, setLoader] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const [amountDetails, setAmountDetials] = useState({
    totalAmount: "",
    subTotal: "",
    texAmount: "",
  });

  /////====gestdetail====///

  const [guestDetails, setGuestDetails] = useState(null);

  const updateGuestDetails = (details) => {
    setGuestDetails(details);
  };

  // ===================== Added Into Cart ===============
  const handleAddCart = (value) => {
    if (cartValue && cartValue.length > 0) {
      // Check if the item already exists in the cart
      const index = cartValue.findIndex(
        (filtValue) =>
          filtValue.Name === value.Name &&
          filtValue.qty === value.qty &&
          filtValue.spice === value.spice
      );

      if (index >= 0) {
        // If the item exists in the cart, update its quantity
        const previousData = [...cartValue]; // Create a copy of the cartValue array
        previousData[index].itemQty = cartValue[index].itemQty + 1;
        let finalPrice = parseFloat(cartValue[index].fixedPrice) * cartValue[index].itemQty // food price
        previousData[index].price = finalPrice.toFixed(2);
        setCartValue(previousData); // Update the state with the modified array
        addtoLocatStorage(previousData); // Storing into local storage
        openNotification(previousData[index], "Item Quantity Increase by 1");
      } else {
        openNotification(value, "Added into cart");
        const addCartData = {
          ...value,
          fixedPrice: value.price,
        };
        // If the item is not in the cart, add it
        setCartValue([...cartValue, addCartData]); // Add the new item to the cart
        addtoLocatStorage([...cartValue, value]); // Storing into local storage
        setCartCount(cartValue.length + 1); // Update the cart count
      }
    } else {
      openNotification(value, "Added into cart");
      const addCartData = {
        ...value,
        fixedPrice: value.price,
      };
      // If the cart is empty, add the item as the first item
      setCartValue([addCartData]);
      addtoLocatStorage([addCartData]); // Storing into local storage
      setCartCount(1);
    }
  };

  console.log(cartValue)

  //   =================== Open Notiification ================
  const openNotification = (value, message) => {
    const key = `open${Date.now()}`;
    const listDesign = (
      <List itemLayout="horizontal">
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar src={value.Image} />}
            title={`${value.Name} ${
              value.spice === null ? "" : `(${value.spice} spicy)`
            }`}
            description={`Price: ${value.price}  Qty: ${value.itemQty}`}
          />
        </List.Item>
      </List>
    );
    api.open({
      message: message,
      description: listDesign,
      key,
    });
  };

  // ============= Price Details ==================

  const cartAmounts = () => {
    if (cartValue && cartValue.length > 0) {
      let total = 0;
      cartValue.forEach((product) => {
        total += parseFloat(product.price);
      });

      let taxRate;
      const branchDetail = JSON.parse(sessionStorage.getItem("branch"));

      if(branchDetail && branchDetail.branch_slug === "indian-delight-hattiesburg"){
        taxRate = 10;
      }else{
        taxRate = 7
      }

      const totalTax = (total * taxRate) / 100;
      const tax = parseFloat(totalTax.toFixed(2));
      const itemTotal = parseFloat(total.toFixed(2));
      const totalAdd = itemTotal + tax + tipPrice;
      const finalAmount = parseFloat(totalAdd.toFixed(2));

      const amountData = {
        texAmount: tax,
        subTotal: itemTotal,
        totalAmount: finalAmount,
        tipPrice: tipPrice,
        taxRate:taxRate
      };
      const jsonString = JSON.stringify(amountData);
      const encodedString = btoa(jsonString);
      sessionStorage.setItem("amountDetail", encodedString);

      setAmountDetials({
        ...amountDetails,
        texAmount: tax,
        subTotal: itemTotal,
        totalAmount: finalAmount,
        taxRate:taxRate
      });
    }
  };

  const contextValue = {
    cartCount,
    handleCartList,
    handleAddCart,
    showCartList,
    cartValue,
    branchDetail,
    setBranchDetail,
    guestDetails,
    setGuestDetails,
    updateGuestDetails,
    branchData,
    setCartValue,
    setCartCount,
    setShowCartList,
    tipPrice,
    setTipPrice,
    pickupNote,
    setPickupNote,
    DeliveryTime,
    setDeliveryTime,
    loader,
    setLoader,
    setBranchData,
    selectedBranch,
    setSelectedBranch,
    amountDetails,
    cartAmounts,
  };
  //brnchdetai

  // =================== add to local Storage ==================

  const addtoLocatStorage = (data) => {
    localStorage.setItem("cart", JSON.stringify(data));
  };

  return (
    <CartContext.Provider value={contextValue}>
      {contextHolder}
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;

import React, { useEffect, useState } from "react";
import { getProfile } from "../../services/localStorageServices";
import axios from "axios";
import { Button, Form, Input, message } from "antd";

export default function ProfileDetails() {
  const { profile } = getProfile();
  const formRef = React.useRef(null);
  const [phoneNumber, setPhoneNumber] = useState("");

  // ============== Update Personal Details =============
  const onUpdate = async (values) => {
    try {
      const formData = new FormData();
      formData.append("action", "update");
      // formData.append("usertype", "user");
      // formData.append("id", values.id);
      formData.append("id", profile.id);
      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("phone", phoneNumber);

      const response = await axios.post(
        "https://indiandelightms.com/api/user-detail.php",
        formData
      );

      if (response.data) {
        const profileData = {
          id: profile.id,
          name: values.name,
          phone: values.phone,
          email: values.email,
        };
        sessionStorage.setItem("profile", JSON.stringify(profileData));
        message.success("Updated Successfully");
      }
    } catch (error) {
      console.log("Update failed:", error);
    }
  };

  // =============== Password Update =============
  const onUpdatepassword = async (values) => {
    try {
      const formData = new FormData();
      formData.append("action", "update");
      formData.append("id", profile.id);
      formData.append("password", values.password);

      const response = await axios.post(
        "https://indiandelightms.com/api/user-detail.php",
        formData
      );

      message.success("Password Cheanged Successfully");
    } catch (error) {
      message.error("Failed to update");
      console.log("Update failed:", error);
    }
  };

  // ============= Set Data opn Form ===========
  useEffect(() => {
    formRef.current?.setFieldsValue({
      name: profile.name,
      phone: profile.phone,
      email: profile.email,
    });
  }, []);

  // ================= Phone Number Input ====================

  const formatPhoneNumber = (input) => {
    // Remove non-numeric characters from the input
    const numericInput = input.replace(/\D/g, "");

    // Apply phone number formatting (adjust as needed)
    const formattedNumber = numericInput.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );

    return formattedNumber;
  };

  const handleChange = (e) => {
    const input = e.target.value;
    const formattedNumber = formatPhoneNumber(input);
    formRef.current?.setFieldsValue({
      phone: formattedNumber,
    });
    setPhoneNumber(formattedNumber);
  };

  return (
    <section className="OrderList-Section">
      <div className="container-fluid">
        <div className="OderList-Head">
          <h5>Profile Details</h5>
        </div>
        <div className="OrderList-main-con" id="style-1">
          <div className="row">
            <div className="col-md-12">
              <div className="Profile-List-Card-Body">
                <div className="Profile-List-inputs">
                  <Form
                    name="update"
                    className="login-form"
                    layout="vertical"
                    ref={formRef}
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={onUpdate}
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <Form.Item
                          hasFeedback
                          label="Full Name"
                          name="name"
                          validateTrigger="onBlur"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your full name!",
                            },
                          ]}
                        >
                          <Input placeholder="Enter The Name " />
                        </Form.Item>
                      </div>
                      <div className="col-md-6">
                        <Form.Item
                          hasFeedback
                          label="Phone"
                          name="phone"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your phone number!",
                            },
                            {
                              min: 10,
                              message: "Please enter valid phone number",
                            },
                            {
                              max: 15,
                              message: "please enter valid phone number",
                            },
                          ]}
                        >
                          <Input
                            onChange={handleChange}
                            prefix={"+1"}
                            placeholder="Phone No."
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-6">
                        <Form.Item
                          hasFeedback
                          label="email"
                          name="email"
                          validateTrigger="onBlur"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your email!",
                            },
                            {
                              type: "email",
                              message: "Please enter a valid email address!",
                            },
                          ]}
                        >
                          <Input placeholder="Enter Email here" />
                        </Form.Item>
                      </div>
                      <Form.Item>
                        <Button className="Update" htmlType="submit">
                          Update Detail
                        </Button>
                      </Form.Item>
                    </div>
                    <div className="OderList-Head">
                      <h5>Update Password</h5>
                    </div>
                  </Form>
                  <Form
                    name="update"
                    className="login-form"
                    layout="vertical"
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={onUpdatepassword}
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Item
                          hasFeedback
                          label="Password"
                          name="password"
                          validateTrigger="onBlur"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your password!",
                            },
                          ]}
                        >
                          <Input.Password placeholder="Enter your password " />
                        </Form.Item>
                      </div>
                      <div className="col-md-6">
                        <Form.Item
                          hasFeedback
                          label="Confirm Password"
                          name="confirmPassword"
                          validateTrigger="onBlur"
                          rules={[
                            {
                              required: true,
                              message: "Please confirm your password!",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("password") === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  "Passwords do not match!"
                                );
                              },
                            }),
                          ]}
                        >
                          <Input.Password placeholder="Enter your password " />
                        </Form.Item>
                      </div>
                    </div>

                    <Form.Item>
                      <Button className="Update" htmlType="submit">
                        Update Password
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import React, { useEffect } from "react";
import "../styles/home.css";
import TestimonialSection from "../components/testimonial/TestimonialSecction";
import AboutSection from "../components/about/aboutSection";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import BranchSection from "../components/Branchs/BranchSection";
import PortfolioSection from "../components/portfolio/PortfolioSection";

export default function Home() {
  useEffect(() => {
    // Scroll to the top of the page on component mount
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <div className="home-container margin-top ">
        <div className="home-contant" >
         
          {/* <h3>Welcome To!</h3> */}
          <h1 className="sectionTitle font-style"> Your One Stop for </h1>
          <p className="second-font"> Authentic</p>
          <h1 className="sectionTitle font-style">Indian Flavours</h1>
          {/* <Link to= "/menu" ><button>Order Now</button></Link> */}
        </div>
      </div>
      <BranchSection/>
      <AboutSection />
      <TestimonialSection />
      <PortfolioSection />
      {/* <ContactUs /> */}
      <Footer />
    </div>
  );
}

import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import PersonalData from "../components/placeOrder/personalData";
import AddressData from "../components/placeOrder/addressData";
import "../styles/placeOrder.css";
import PaymentDetail from "../components/placeOrder/paymentDetail";
import TotalDetail from "../components/placeOrder/totalDetail";

export default function PlaceOrder() {
  useEffect(() => {
    // Scroll to the top of the page on component mount
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar postion={true} />
      <div className="place-order-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 order-2 order-lg-1" style={{paddingLeft:"40px"}}>
              <PersonalData />
              <AddressData />
              <PaymentDetail />
            </div>
            <div className="col-lg-4 order-1 order-lg-2">
              {/* <BranchData /> */}
              <TotalDetail />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

import { Button } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/branches.css";
import BookingType from "../booking/BookingType";
import { SkeletonTheme } from "react-loading-skeleton";
import { useArrayContext } from "../../context/iconsContext";
import { Input } from 'antd';
export default function BranchCard({
  title,
  link,
  image,
  address,
  social,
  isClose,
}) {
  const [open, setOpen] = useState(false);
  const handleChange = () => setOpen((prev) => !prev);
  const { iconsList } = useArrayContext();

  return (
    <div>
      <SkeletonTheme baseColor="#dbdbdb99" highlightColor="white">
        {/* {showBranchCard && ( */}
        <div className="branch-card container">
          <div className="branch-img">
            <img src={image} alt="" />
          </div>
          <div className="branch-details">
            <h5 className="brach-title">{title}</h5>
            <p>{address}</p>
            {(isClose === "0" || isClose === 0) && <span style={{color:"red"}}>Booking Closed</span> }
            <div className="branch-social">
              <ul>
                {social.map((value) => (
                  <li>
                    <Link className={value.name} target="_blank" to={value.link}>
                      {iconsList.map((valueItem) =>
                        valueItem.name === value.name ? valueItem.icon : null
                      )}
                    </Link>
                  </li>
                ))}
              </ul>
             
              <div className="branch-button">
                <Button
                className="bookButton"
                  onClick={handleChange}
                  type="prime"
                >
                  Order Now
                </Button>
              </div>
              <BookingType
                open={open}
                setOpen={setOpen}
                branch={title}
                link={link}
              />
            </div>
          </div>
        </div>
        {/* )} */}
      </SkeletonTheme>
      {/* {isLoading && (
        <Branchcardskeltion/>
      )} */}
      <div></div>
    </div>
  );
}

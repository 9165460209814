import { Link, useNavigate } from "react-router-dom";
import WebLogo from "../assets/images/Indian Delight Logo1.png";
import { useEffect, useRef, useState } from "react";
import "animate.css";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space, Typography, Button, Menu } from "antd";
import "../styles/navbar.css";
import BookingType from "./booking/BookingType";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { getProfile, getToken } from "../services/localStorageServices";
import { useDispatch, useSelector } from "react-redux";
import { setUserToken } from "../features/authSlice";
import { UserOutlined } from "@ant-design/icons";
import { useCartContext } from "../context/addToCart";
import axios from "axios";
function Navbar1({ postion, hideScroll }) {
  const navigate = useNavigate();

  // ============= Log Out Hanlde ===========
  const logoutHandle = () => {
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("profile");
    navigate("/");
  };

  const menu = (
    <Menu>
      <Menu.Item key="profile">
        <a href="/user/profile">Profile</a>
      </Menu.Item>
      <Menu.Item key="orders">
        <a href="/user/order">Orders</a>
      </Menu.Item>
      <Menu.Item key="logout">
        <span onClick={logoutHandle}>Logout</span>
      </Menu.Item>
    </Menu>
  );

  const [scrolling, setScrolling] = useState(false);
  const [open, setOpen] = useState(false);
  const {
    branchData,
    setBranchData,
    setLoader,
    setGuestDetails,
    setSelectedBranch,
    setCartValue,
    setCartCount,
    cartAmounts,
    tipPrice,
    cartValue,
  } = useCartContext();
  const [branch, setBranch] = useState({
    title: "",
    link: "",
  });

  const handleChange = (title, link) => {
    setOpen((prev) => !prev);
    setBranch({
      title: title,
      link: link,
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    // =============== Update Icon =========
    window.addEventListener("scroll", handleScroll);

    return () => {
      console.log("removed");
    };
  }, []);

  const handleNavClick = (pageUrl, sectionId) => {
    // Navigate to the target page
    navigate(pageUrl);

    // Add a delay to ensure the page is loaded (you may need to adjust the delay)
    setTimeout(() => {
      // Scroll to the section on the new page
      const targetSection = document.querySelector(sectionId);
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 500); // Adjust the delay as needed
  };

  const [expanded, setExpanded] = useState(false);
  const navbarRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      // Check if the clicked element is not part of the navbar
      if (navbarRef.current && !navbarRef.current.contains(e.target)) {
        // Close the navbar if it's open
        if (expanded) {
          setExpanded(false);
        }
      }
    };

    // Add click event listener to the document
    document.addEventListener("click", handleOutsideClick);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [expanded]);

  // =========================== Fethc Branch List =======================

  // useEffect(() => {
  //   // Only fetch branch data if branchData is empty
  //   if (branchData.length === 0) {
  //     setLoader(true);
  //     fetch(
  //       "https://indiandelightms.com/api/branch-detail.php?action=fetch&limit=10&pages=0"
  //     )
  //       .then((response) => response.json())
  //       .then((data) => {
  //         setBranchData(data.list);
  //         setLoader(false);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching branch data:", error);
  //       });
  //   }
  // }, []);

  const fetchBranchList = () => {
    setLoader(true);

    axios
      .get("https://indiandelightms.com/api/branch-detail.php", {
        params: {
          action: "fetch",
          limit: 10,
          pages: 0,
        },
      })
      .then((response) => {
        console.log(response.data)
        setBranchData(response.data.list);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching branch data:", error);
      });
  };
  useEffect(() => {
    let timeBounce;

    if (branchData.length === 0) {
      timeBounce = setTimeout(() => {
        fetchBranchList();
      }, 500);
    }
    return () => clearTimeout(timeBounce);
  }, []);

  const { access_token } = getToken();
  const { profile } = getProfile();
  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(setUserToken({ token: access_token }));
  }, [access_token, dispatch]);

  useEffect(() => {
    const guestData = JSON.parse(sessionStorage.getItem("guestDetails"));

    if (
      guestData ||
      guestData !== null ||
      (guestData !== undefined && guestData !== "")
    ) {
      setGuestDetails(guestData);
    }
  }, []);

  useEffect(() => {
    const branch = JSON.parse(sessionStorage.getItem("branch"));

    if (branch || branch !== null || (branch !== undefined && branch !== "")) {
      setSelectedBranch(branch);
    }
  }, []);

  useEffect(() => {
    cartAmounts();
    if (cartValue && cartValue.length > 0) {
      setCartCount(cartValue.length);
    }
  }, [cartValue, tipPrice]);

  useEffect(() => {
    const cartData = JSON.parse(localStorage.getItem("cart"));
    if (
      (cartData && cartData !== null) ||
      cartData !== undefined ||
      cartData !== ""
    ) {
      setCartValue(cartData);
      if (cartData && cartData.length > 0) {
        setCartCount(cartData.length);
      }
    }
  }, []);

  return (
    <div>
      <div className="nav-controlls">
        <Navbar
          collapseOnSelect
          ref={navbarRef}
          bg="light"
          expand="lg"
          expanded={expanded}
          className={
            hideScroll
              ? `navbar-container relative `
              : postion
              ? `navbar-container sticky`
              : `navbar-container ${scrolling ? "scrolling" : ""}`
          }
        >
          <Container className="nav-controlls">
            <Navbar.Brand href="/">
              <div className="web-logo">
                <img src={WebLogo} alt="Indian Delight" />
              </div>
            </Navbar.Brand>
            <Navbar.Toggle
              onClick={() => setExpanded(!expanded)}
              aria-controls="basic-navbar-nav"
            />
            {token ? null : (
              <Nav.Link
                href="#features"
                className="signinbuttionsection d-lg-none d-block"
              >
                {" "}
                <Link className="nev-color" to="/account/sign-in">
                  <button
                    style={{ minWidth: "80px" }}
                    className="sign-up-button"
                  >
                    Sign In
                  </button>
                </Link>
              </Nav.Link>
            )}
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link>
                  {" "}
                  <Link className="nev-color" to="/">
                    Home
                  </Link>
                </Nav.Link>

                <Nav.Link>
                  {" "}
                  <Dropdown
                    menu={{
                      items: branchData.map((valueItem, i) => ({
                        key: i + 1,
                        label: (
                          <span
                            onClick={() =>
                              handleChange(
                                valueItem.name,
                                valueItem.branch_slug
                              )
                            }
                          >
                            {valueItem.name}
                          </span>
                        ),
                      })),
                    }}
                  >
                    <Typography.Link>
                      <Space className="navlink nev-color">
                        Order Now
                        <DownOutlined />
                      </Space>
                    </Typography.Link>
                  </Dropdown>
                </Nav.Link>
                <Nav.Link>
                  <Link className="nev-color" to="/aboutUs">
                    About Us
                  </Link>
                </Nav.Link>
                <Nav.Link>
                  {" "}
                  <Dropdown
                    menu={{
                      items: branchData.map((valueItem, i) => ({
                        key: i + 1,
                        label: (
                          <Link to={`/menu/${valueItem.branch_slug}`}>
                            {valueItem.name}
                          </Link>
                        ),
                      })),
                    }}
                  >
                    <Typography.Link>
                      <Space className="navlink nev-color">
                        Catering Menu
                        <DownOutlined />
                      </Space>
                    </Typography.Link>
                  </Dropdown>
                </Nav.Link>

                <Nav.Link>
                  {" "}
                  <Space
                    style={
                      hideScroll
                        ? { color: "#111" }
                        : scrolling
                        ? { color: "#111" }
                        : { color: "#000" }
                    }
                    onClick={() => handleNavClick("/", "#reviews")}
                    className="navlink nev-color"
                  >
                    Customer Reviews
                  </Space>
                </Nav.Link>
                <Nav.Link>
                  {" "}
                  <Link className="nev-color" to="/contact-us">
                    Contact Us
                  </Link>
                </Nav.Link>
                {/* {token ? null : (
                  <Nav.Link
                    href="#features"
                    className="signinbuttionsection d-lg-none d-block"
                  >
                    {" "}
                    <Link className="nev-color" to="/account/sign-in">
                      <button className="sign-up-button">Sign In</button>
                    </Link>
                  </Nav.Link>
                )} */}
              </Nav>
            </Navbar.Collapse>
            {token ? (
              <div>
                <Dropdown overlay={menu}>
                  <Button
                    style={{
                      color: "#fff",
                      borderColor: "#fff",
                      textTransform: "capitalize",
                    }}
                    icon={<UserOutlined />}
                    className="sign-up-button"
                  >
                    {profile.name}
                  </Button>
                </Dropdown>
              </div>
            ) : (
              <Nav.Link
                href="#features"
                className="signinbuttion d-lg-block d-none"
              >
                <Link className="nev-color" to="/account/sign-in">
                  <button
                    style={{ minWidth: "80px" }}
                    className="sign-up-button"
                  >
                    Sign In
                  </button>
                </Link>
              </Nav.Link>
            )}
          </Container>
        </Navbar>
      </div>

      <BookingType
        open={open}
        setOpen={setOpen}
        branch={branch.title}
        link={branch.link}
      />
    </div>
  );
}

export default Navbar1;

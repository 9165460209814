import React from "react";
import knife from "../../assets/images/knife.png";
import shaker from "../../assets/images/shaker.png";
import chef from "../../assets/images/chef.png";
import star from "../../assets/images/stars.png"
export default function WhyChoose() {
  return (
    <div className="WhyChooseSection">
      <div className="row">
        <div className="col-md-4">
          <div className="whyChooseCard">
            <div className="whyChooseIcon">
              <img src={star} />
            </div>
            <div className="whyChooseTitle">
              <h3>Highest Rated Indian Restaurant in Mississippi</h3>
            </div>
            <div className="whyChooseDesc">
              {" "}
              <p>
                Discover the culinary excellence at Indian Delight, the
                top-rated Indian restaurant in Mississippi. From Hettisburg to
                Ocean Spring, our exceptional ratings reflect the commitment to
                providing an unparalleled dining experience.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="whyChooseCard">
            <div className="whyChooseIcon">
              <img src={shaker} />
            </div>
            <div className="whyChooseTitle">
              <h3>AUTHENTIC SPICES</h3>
            </div>
            <div className="whyChooseDesc">
              {" "}
              <p>
                Savor a symphony of authentic flavors at Indian Delight, where
                our culinary experts meticulously craft each dish. Immerse
                yourself in the essence of India, where every bite is a perfect
                blend of aromatic spices and perfected cooking techniques.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="whyChooseCard">
            <div className="whyChooseIcon">
              <img src={chef} />
            </div>
            <div className="whyChooseTitle">
              <h3>AN EXTRAORDINARY EXPERIENCE</h3>
            </div>
            <div className="whyChooseDesc">
              {" "}
              <p>
                Experience Indian Delight, where warm hospitality meets
                unforgettable moments. Beyond our delectable cuisine, our
                inviting ambiance and attentive staff ensure that each visit is
                a celebration. Join us for exceptional dining and create
                cherished memories.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

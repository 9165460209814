import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../styles/booking.css";
import PartnerCard from "../components/booking/PartnerCard";
import Title from "../components/sectionTitle/title";
import { Link, useParams } from "react-router-dom";
import { useCartContext } from "../context/addToCart";

export default function DeliveryPartner() {
  const { branch } = useParams();
  const { branchData } = useCartContext();
  const [partnerList, setPartnerList] = useState(null);

  useEffect(() => {
    const filterData = branchData.find(
      (findValue) => findValue.branch_slug === branch
    );

    if (filterData) {
      setPartnerList(filterData.partners);
    }
  }, [branchData, branch]);

  useEffect(() => {
    // Scroll to the top of the page on component mount
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <div className="delivery-partner-page">
        <div className="deliveryPartnerBanner">
          <h1>Order Delivery, Some of our delivery partners.</h1>
        </div>
        <div className="partnerList section">
          <Title text="Select Delivery Partner" />
          <div className="container d-flex justify-content-center">
            <div className="row">
              {partnerList &&
                partnerList.map((item) => (
                  <div className="col-xl-4 col-sm-6 col-12 mb-4 d-flex justify-content-center">
                    <a href={item.link} target="_blank" className="PartnerCard">
                      <div className="partnerImg">
                        <img src={item.image} alt={item.name} />
                      </div>
                    </a>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

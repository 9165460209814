import { Button, Drawer } from "antd";
import React, { useState } from "react";
import { Col, Divider, Row } from "antd";
import { Typography } from "antd";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { MdCall, MdEmail } from "react-icons/md";
import moment from "moment";
import { PiWarningCircleBold } from "react-icons/pi";

export default function DrawerOrders({ detaileView }) {
  const { Title, Text } = Typography;
  const [open, setOpen] = useState(false);

  const checkStatus = (status) => {
    if (status === "success") {
      return "green";
    } else if (status === "failed") {
      return "red";
    } else {
      return "orange";
    }
  };

  const checkStatusIcon = (status) => {
    if (status === "success") {
      return <AiOutlineCheckCircle />;
    } else if (status === "failed") {
      return <AiOutlineCloseCircle />;
    } else {
      return <PiWarningCircleBold />;
    }
  };
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        type="button"
        style={{
          padding: "2px 5px",
          borderRadius: "5px",
          color: "white",
          backgroundColor: "#000",
        }}
      >
        View All
      </Button>
      <Drawer
        title={detaileView.branchName}
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
        width={450}
      >
        <div>
          <Row>
            <Col span={12}>
              <Title level={5}>Order Number</Title>
            </Col>
            <Col style={{ display: "flex", justifyContent: "end" }} span={12}>
              <Title className="valueBooking" level={5}>
                {detaileView.Order_id}
              </Title>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Title level={5}>Booking Status</Title>
            </Col>
            <Col style={{ display: "flex", justifyContent: "end" }} span={12}>
              <Button
                shape="round"
                className="checkStatus"
                icon={checkStatusIcon(detaileView.payment_status)}
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                  background: checkStatus(detaileView.payment_status),
                  border: "unset !important",
                }}
              >
                {detaileView.payment_status
                  ? detaileView.payment_status
                  : "Pending"}
              </Button>
            </Col>
          </Row>

          <Divider orientation="left">Branch Detail</Divider>
          <Row>
            <Col span={24}>
              <Title
                style={{
                  display: "flex",
                  alignItems: "center",
                  textTransform: "capitalize",
                }}
                level={5}
              >
                {detaileView.branchName}
              </Title>
            </Col>
            <Col span={12}>
              <Text
                className="valueBooking"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <MdCall />
                &nbsp; {detaileView.branchPhone}
              </Text>
            </Col>
            <Col span={12}>
              <Text
                className="valueBooking"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <MdEmail />
                &nbsp; {detaileView.branchEmail}
              </Text>
            </Col>
            <Col span={24}>
              <Text
                className="valueBooking"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <MdEmail />
                &nbsp; {detaileView.branchAddress}
              </Text>
            </Col>
          </Row>

          {/* ================= eNach Details =========== */}
          <Divider orientation="left">Food Details</Divider>

          {detaileView.foods.map((item) => (
            <Row>
              <Col span={10}>
                <Text level={5}>
                  {item.Name} x {item.food_qty}
                </Text>
              </Col>
              <Col
                style={{ display: "flex", justifyContent: "center" }}
                span={7}
              >
                <Text level={5}>({item.food_spice})</Text>
              </Col>
              <Col style={{ display: "flex", justifyContent: "end" }} span={7}>
                <Text className="valueBooking" level={5}>
                  ${item.price}
                </Text>
              </Col>
            </Row>
          ))}

          <Divider orientation="left">Booking Details</Divider>
          {/* ======================= Total Detail ================= */}
          <Row>
            <Col span={10}>
              <Text level={5}>Pickup Note</Text>
            </Col>
            <Col span={4}>
              <Text level={5}>:</Text>
            </Col>
            <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
              <Text className="valueBooking" level={5}>
                {detaileView.pickup_note}
              </Text>
            </Col>
            <Col span={10}>
              <Text level={5}>Booking Date & Time</Text>
            </Col>
            <Col span={4}>
              <Text level={5}>:</Text>
            </Col>
            <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
              <Text className="valueBooking" level={5}>
                {moment(detaileView.created_at).format("llll")}
              </Text>
            </Col>

            <Col span={10}>
              <Text level={5}>Subtotal</Text>
            </Col>
            <Col span={4}>
              <Text level={5}>:</Text>
            </Col>
            <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
              <Text className="valueBooking" level={5}>
                ${detaileView.food_total}
              </Text>
            </Col>

            <Col span={10}>
              <Text level={5}>{detaileView.branchName === "Indian Delight Hattiesburg" ? "10%" : "7%"} Tax</Text>
            </Col>
            <Col span={4}>
              <Text level={5}>:</Text>
            </Col>
            <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
              <Text className="valueBooking" level={5}>
                ${detaileView.sales_tax}
              </Text>
            </Col>

            <Col span={10}>
              <Text level={5}>Tip</Text>
            </Col>
            <Col span={4}>
              <Text level={5}>:</Text>
            </Col>
            <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
              <Text className="valueBooking" level={5}>
                ${detaileView.tip}
              </Text>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={10}>
              <Text level={5}>Total</Text>
            </Col>
            <Col span={4}>
              <Text level={5}>:</Text>
            </Col>
            <Col style={{ display: "flex", justifyContent: "end" }} span={10}>
              <Text className="valueBooking" level={5}>
                ${detaileView.total_amount}
              </Text>
            </Col>
          </Row>
        </div>
      </Drawer>
    </>
  );
}

import BranchCard from "./BranchCard";
import Title from "../sectionTitle/title";
import "../../styles/branches.css";
import React from "react";
import { useCartContext } from "../../context/addToCart";

export default function BranchSection() {
  const { branchData } = useCartContext();

  return (
    <div className="branch-section section">
      <Title
        text="JOIN US AT ANY OF OUR LOCATIONS!"
        desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
      />
      <div className="container">
        <div className="row">
          {branchData.map((branchItem) => (
            <div
              className="col-md-6 d-flex justify-content-center"
              key={branchItem.id}
            >
              <BranchCard
                isClose={branchItem.is_closed}
                image={branchItem.branch_img}
                title={branchItem.name}
                address={branchItem.address}
                social={branchItem.social}
                link={branchItem.branch_slug}
                id={branchItem.id}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import "../../styles/placeOrder.css";
import { BiSolidPhoneCall, BiSolidUser } from "react-icons/bi";
import { FaLocationDot } from "react-icons/fa6";
import { useCartContext } from "../../context/addToCart";

export default function AddressData() {
  const {selectedBranch} = useCartContext();
  
  return (
    <div className="stepsCard">
      <span className="stepIcon">
        <FaLocationDot />
      </span>
      <h4>Pickup Detail</h4>
     
      <div className="personalDetails" >
        <h5>{selectedBranch && selectedBranch.name}</h5>
        <div className="contact-detail">
          <p>
            <BiSolidPhoneCall />
            +1&nbsp;{selectedBranch && selectedBranch.phone}
          </p>
        </div>
        <div className="contact-address d-flex align-items-start">
          <FaLocationDot />
          &nbsp;
          <p> {selectedBranch && selectedBranch.address}</p>
        </div>
      </div>
       
    </div>
  );
}

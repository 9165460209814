import React from "react";
import empty from "../../assets/images/empty.webp";

export default function EmptyComp({ message }) {
  return (
    <div style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
      <img style={{maxWidth:"300px"}} src={empty} alt="empty image" />
      <h5>{message}</h5>
    </div>
  );
}

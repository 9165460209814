import React from "react";
import "../../styles/placeOrder.css";
import { MdPayment } from "react-icons/md";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useCartContext } from "../../context/addToCart";
import {
  getGuestProfile,
  getProfile,
} from "../../services/localStorageServices";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function PaymentDetail() {
  const { selectedBranch, setCartValue } = useCartContext();
  // const getCurrentTime = () => {
  //   const now = new Date();
  //   now.setMinutes(now.getMinutes() + 30); // Add 30 minutes to the current time
  //   const hours = now.getHours().toString().padStart(2, '0');
  //   const minutes = now.getMinutes().toString().padStart(2, '0');
  //   return `${hours}:${minutes}`;
  // };
  const getCurrentTime = () => {
    const now = new Date();
    now.setMinutes(now.getMinutes() + 30); // Add 30 minutes to the current time

    // Set the time zone to USA Eastern Time (ET)
    const options = {
      timeZone: "America/New_York",
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    };
    const timeString = now.toLocaleString("en-US", options);

    return timeString;
  };

  const navigate = useNavigate();

  // const paypalOptions = {
  //   "client-id":
  //     "ARFGEg0S-KVVu7F1qY1dDvwMPKVxT7uX2ROoXPJEz8jst3-9Ha5Rzpgtsh_V2YEJWpQzEz_K5EV4OQbz",
  // }; // testing client id

    const paypalOptions = {
    "client-id":
      "AWUqRQaeALtMirBalGZN2Vo2ggcLInY5sSE_yiX5RjjDEeDFIw94SmHwOuaHiJ1jWc7E2lzm5PVPWr1K",
  }; // live client id

  const {
    cartValue,
    setCartCount,
    DeliveryTime,
  } = useCartContext();
  const { profile } = getProfile();

  const { token } = useSelector((state) => state.auth);

  const handleOrderAdd = async (responseId, responseStatus) => {
    const { guestDetail } = getGuestProfile();
    if (!token && !guestDetail) {
      alert("Please login to place an order.");
      return;
    }
    const encodedString = sessionStorage.getItem("amountDetail");
    let paymentDetails = null;

    if (encodedString) {
      // Decode the string and parse it as JSON
      const jsonString = atob(encodedString);
      paymentDetails = JSON.parse(jsonString);
    }
    const valueData = {
      totalAmount: paymentDetails.totalAmount,
      foodAmount: paymentDetails.subTotal,
      tipAmount: paymentDetails.tipPrice,
      salesTax: paymentDetails.texAmount,
      userId: !token ? guestDetail.id : profile.id,
      branchId: selectedBranch && selectedBranch.id,
      pickupNote: sessionStorage.getItem("pickupNote"),
      PickupTime: DeliveryTime ? DeliveryTime : getCurrentTime(),
      action: "order",
      foodList: cartValue.map((valueData) => ({
        foodId: valueData.id,
        foodSpice: valueData.spice,
        foodQty: valueData.itemQty,
      })),

      status: responseStatus,
      responseID: responseId,
    };
    console.log(valueData);
    const headers = {
      "Content-Type": "application/json",
    };
    const res = await axios.post(
      "https://indiandelightms.com/api/order-detail.php",
      valueData,
      { headers: headers }
    );

    if (res.data) {
      if (responseStatus === "success") {
        localStorage.removeItem("cart");
        setCartValue([]);
        setCartCount(0);
        sessionStorage.removeItem("guestDetails");
        sessionStorage.removeItem("branch");
        sessionStorage.removeItem("pickupNote");
        sessionStorage.removeItem("amountDetail");
        navigate("/booking-success/" + res.data.generateId);
      }
      if (responseStatus === "failed") {
        alert("the payment failed please try again");
        navigate("/place-order/");
      }
    }
  };

  const getTotalPrice = () => {
    let paymentDetails = null;
    const encodedString = sessionStorage.getItem("amountDetail");

    if (encodedString) {
      // Decode the string and parse it as JSON
      const jsonString = atob(encodedString);
      paymentDetails = JSON.parse(jsonString);
    }
    return paymentDetails.totalAmount;
  };

  return (
    <div className="stepsCard">
      <span className="stepIcon">
        <MdPayment />
      </span>
      <h4 className="mb-5">Payment Method</h4>
      {/* <button onClick={() => handleOrderAdd()}>submit</button> */}
      {profile?.id || getGuestProfile().guestDetail ? (
        <PayPalScriptProvider options={paypalOptions}>
          <PayPalButtons
            createOrder={(data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      currency_code: "USD",
                      value: getTotalPrice(),
                    },
                    // reference_id: "d9f80740-38f0-11e8-b467-0ed5f89f718b",
                  },
                ],
              });
            }}
            onApprove={async (data, actions) => {
              try {
                // Capture the PayPal order
                const order = await actions.order.capture();
                const orderIdRes = order.id;
                handleOrderAdd(orderIdRes, "success");
              } catch (error) {
                console.error("Error capturing order:", error);
                // If payment capture fails, make an API call for failure
                handleOrderAdd(null, "failed");
              }
            }}
            onError={(err) => {
              console.log(err);
              navigate("/place-order");
              // paymentUpdate(null, "failed")
            }}
          />
        </PayPalScriptProvider>
      ) : (
        <p>Please Login or Guest Login to enable PayPal payment.</p>
      )}
    </div>
  );
}

import { Modal } from "antd";
import React from "react";
import delivery from "../../assets/images/fast-delivery.png";
import pickup from "../../assets/images/tray.png";
import Title from "../sectionTitle/title";
import "../../styles/booking.css";
import { Link } from "react-router-dom";

export default function BookingType({ branch, link, setOpen, open }) {
  return (
    <>
      <Modal
        footer={[]}
        onCancel={() => setOpen(false)}
        title=""
        centered
        open={open}
      >
        <div className="bookingType mt-4">
          <Title text={branch} />
          <div className="row">
            <div
              className="col-6 d-flex justify-content-center"
              style={{ paddingBottom: "10px" }}
            >
              <Link
                to={`/foods/${link}`}
                className="bookingCard"
                onClick={() => setOpen((prev) => !prev)}
              >
                <img src={pickup} alt="Pickup Foods" />
                <h5>Pick up from resturant</h5>
              </Link>
            </div>
            <div
              className="col-6 d-flex justify-content-center"
              style={{ paddingBottom: "10px" }}
            >
              <Link
                to={`/delivery-partners/${link}`}
                className="bookingCard"
                onClick={() => setOpen((prev) => !prev)}
              >
                <img src={delivery} alt="delivery Partner" />
                <h5>Deliver to address</h5>
              </Link>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

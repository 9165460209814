import "../styles/Footer.css";
import "../styles/partners.css";
import { BiSolidPhoneCall } from "react-icons/bi";
import { AiFillMail } from "react-icons/ai";
import { FaLocationDot } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import { Divider } from "antd";
import { useCartContext } from "../context/addToCart";
import logo from "../assets/images/Indian Delight Logo.png";
import { useArrayContext } from "../context/iconsContext";

function Footer() {
  const { branchData } = useCartContext();
  const { iconsList } = useArrayContext();
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
          <div className="col-md-4 d-md-none d-flex justify-content-center align-items-center">
              <img
                style={{ maxWidth: "200px" }}
                src={logo}
                alt="indian delight"
              />
            </div>
            {branchData && branchData.length > 0 ? (
              <div
                className="col-md-4 mb-4 mb-md-0 d-flex flex-column justify-content-center"
                key={branchData[0].id}
              >
                <div className="footerBranchTitle">
                  <h2>{branchData[0].name}</h2>
                </div>
                <div className="foterContactDetail">
                  <div className="call">
                    <BiSolidPhoneCall />
                    &nbsp;<span>+1 {branchData[0].phone}</span>
                  </div>
                  
                  <div className="email">
                    <AiFillMail />
                    &nbsp;<span>{branchData[0].email}</span>
                  </div>
                </div>
                <div className="foterAddress">
                  <FaLocationDot />
                  &nbsp;
                  <span>{branchData[0].address}</span>
                </div>
                <div className="col-md-6 d-flex justify-content-start">
                  <ul className="footerLinks">
                    {branchData[0].social.map((valueItem) => (
                      <li>
                        <NavLink
                          alt={valueItem.name}
                          target="_blank"
                          to={valueItem.link}
                        >
                          {iconsList.map((item) =>
                            item.name === valueItem.name ? item.icon : null
                          )}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ) : null}
            <div className="col-md-4 d-md-flex d-none justify-content-center align-items-center">
              <img
                style={{ maxWidth: "200px" }}
                src={logo}
                alt="indian delight"
              />
            </div>
            {branchData && branchData.length > 0 ? (
              <div
                className="col-md-4 d-flex flex-column justify-content-center"
                key={branchData[1].id}
              >
                <div className="footerBranchTitle">
                  <h2>{branchData[1].name}</h2>
                </div>
                <div className="foterContactDetail">
                  <div className="call">
                    <BiSolidPhoneCall />
                    &nbsp;<span>+1 {branchData[1].phone}</span>
                  </div>
                 
                  <div className="email">
                    <AiFillMail />
                    &nbsp;<span>{branchData[1].email}</span>
                  </div>
                </div>
                <div className="foterAddress">
                  <FaLocationDot />
                  &nbsp;
                  <span>{branchData[1].address}</span>
                </div>

                <div className="col-md-6 d-flex justify-content-start">
                  <ul className="footerLinks">
                    {branchData[1].social.map((valueItem) => (
                      <li>
                        <NavLink
                          alt={valueItem.name}
                          target="_blank"
                          to={valueItem.link}
                        >
                          {iconsList.map((item) =>
                            item.name === valueItem.name ? item.icon : null
                          )}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ) : null}
          </div>
          <Divider style={{ background: "#eee" }} />{" "}
          <div className="row">
            <div className="col-md-6">
              <p className="footercomp" >© 2023 Indian Delight. All rights reserved.</p>
            </div>
            <div className="col-md-6 d-flex justify-content-md-end justify-content-start">
              <ul className="footerLinks text">
                <li>
                  <NavLink to="#">Privacy & Policy</NavLink>
                </li>
                <li>
                  <NavLink to="#">Terms & Condition</NavLink>
                </li>
                <li>
                  <NavLink to="#">Cancellation & Refund Policy</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;

import React, { useEffect } from "react";
import axios from "axios";
import "../../styles/placeOrder.css";
import { BiSolidPhoneCall, BiSolidUser } from "react-icons/bi";
import { AiFillMail } from "react-icons/ai";
import { Button } from "antd";
import { useState } from "react";
import { Form, Input, message, Space } from "antd";
import { getProfile, getToken } from "../../services/localStorageServices";
import { useDispatch, useSelector } from "react-redux";
import { setUserToken } from "../../features/authSlice";
import { useCartContext } from "../../context/addToCart";
import { Link } from "react-router-dom";
export default function PersonalData() {
  const [loginType, setLoginType] = useState(null);
  const { setGuestDetails, guestDetails } = useCartContext();
  const [phoneNumber, setPhoneNumber] = useState("");
  const formRef = React.useRef(null);

  useEffect(() => {
    formRef.current?.setFieldsValue({
      phone: phoneNumber,
    }); // eslint-disable-next-line
  }, []);

  // const onFinish = () => {
  //   message.success("Submit success!");
  // };

  const { access_token } = getToken();
  const { profile } = getProfile();
  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(setUserToken({ token: access_token }));
  }, [access_token, dispatch]);

  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      formData.append("action", "guestRegister");
      formData.append("usertype", "guest");
      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("phone", phoneNumber);
      formData.append("password", "null");

      const response = await axios.post(
        "https://delight.shreekrishnadigital.com/api/user-detail.php",
        formData
      );

      if (response.data) {
        message.success("Guest Register successfully");
        setLoginType("guestRegister");
        setGuestDetails(response.data.profile);
        sessionStorage.setItem(
          "guestDetails",
          JSON.stringify(response.data.profile)
        );
      }
      // Handle success, redirect, or show a success message to the user.
    } catch (error) {
      console.error("Signup Error", error);
      // Handle errors, display an error message, or take appropriate action.
    }
  };

  // ================= Phone Number Input ====================

  const formatPhoneNumber = (input) => {
    // Remove non-numeric characters from the input
    const numericInput = input.replace(/\D/g, "");

    // Apply phone number formatting (adjust as needed)
    const formattedNumber = numericInput.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );

    return formattedNumber;
  };

  const handleChange = (e) => {
    const input = e.target.value;
    const formattedNumber = formatPhoneNumber(input);
    formRef.current?.setFieldsValue({
      phone: formattedNumber,
    });
    setPhoneNumber(formattedNumber);
  };

  return (
    <div className="stepsCard">
      <span className="stepIcon">
        <BiSolidUser />
      </span>
      <h4>Personal Details</h4>
      {token ? (
        <div className="personalDetails">
          <h5>{profile.name}</h5>
          <div className="contactDetail">
            <p>
              <BiSolidPhoneCall />
              {profile.phone}
            </p>
            <p>
              <AiFillMail />
              {profile.email}
            </p>
          </div>
        </div>
      ) : loginType === "guestRegister" || guestDetails ? (
        <div className="personalDetails">
          <h5>{guestDetails.name}</h5>
          <div className="contactDetail">
            <p>
              <BiSolidPhoneCall />
              +1 {guestDetails.phone}
            </p>
            <p>
              <AiFillMail />
              {guestDetails.email}{" "}
            </p>
          </div>
        </div>
      ) : loginType === "guest" ? (
        <div className="personalDetails" style={{ maxWidth: 400 }}>
          <Form
            name="normal_login"
            className="login-form"
            layout="vertical"
            onFinish={onFinish}
            ref={formRef}
          >
            <div className="row">
              <Form.Item
                name="name"
                label="Guest Name"
                rules={[
                  {
                    required: true,
                    message: "Please input your name!",
                  },
                ]}
              >
                <Input placeholder="Enter Name" />
              </Form.Item>
              <div className="col-md-6">
                <Form.Item
                  name="phone"
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone no.!",
                    },
                    {
                      min: 10,
                      max: 15,
                      message: "Please enter a valid Phone!",
                    },
                  ]}
                >
                  <Input
                    onChange={handleChange}
                    prefix={"+1"}
                    placeholder="Phone No."
                  />{" "}
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                    {
                      type: "email",
                      message: "Please enter a valid email!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Email ID." />
                </Form.Item>
              </div>
              <Form.Item>
                <Space>
                  <Button htmlType="submit" className="login-form-button">
                    Submit
                  </Button>
                </Space>
              </Form.Item>
            </div>
          </Form>{" "}
        </div>
      ) : (
        <div className="personalDetails">
          <Button>
            <Link to="/account/sign-in ">Log In</Link>
          </Button>
          <Button onClick={() => setLoginType("guest")}>Guest Log In</Button>
        </div>
      )}
    </div>
  );
}

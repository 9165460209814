import React, { useEffect } from 'react'
import loader from "../assets/images/loader.gif";
import "../styles/loader.css";

export default function Preloader() {
  useEffect(() => {
    // Scroll to the top of the page on component mount
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='preloader'>
      <img src={loader} alt="" />
      <div class="loading-text">
    <h1>LOADING
      <span class="dot-one"> .</span>
      <span class="dot-two"> .</span>
      <span class="dot-three"> .</span>
    </h1>
  </div> 
    </div>
  )
}

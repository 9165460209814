import React, { useState } from "react";
import "../../styles/account.css";
import login from "../../assets/images/login.gif";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Checkbox, Form, Input, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import { useDispatch } from "react-redux";
import { setUserToken } from "../../features/authSlice";
import { setProfile, storeToken } from "../../services/localStorageServices";

export default function SignIn() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);

    try {
      // Construct the data to be sent to the API
      const formData = new FormData();
      formData.append("action", "login");
      formData.append("usertype", "user");
      formData.append("email", values.username);
      formData.append("password", values.password);
      const response = await axios.post(
        "https://indiandelightms.com/api/user-detail.php",
        formData
      );
      // Handle the response here
      if (response.data) {
        if (response.data.status === "Failed") {
          message.error("email or password is wrong");
        } else {
          dispatch(setUserToken({ token: response.data.token }));
          storeToken(response.data.token);
          setProfile(response.data.profile);
          navigate("/");
        }
      }
    } catch (error) {
      // Handle login error, e.g., show an error message
      console.error("Login failed:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{backgroundColor:"white"}}>
      <div className="row">
        <div className="col-md-6 px-5 d-none d-md-flex">
          <img className="widthimage" src={login} alt="login"></img>
        </div>
        <div
          className="col-md-6 px-0 px-md-3 px-lg-5 d-flex align-items-center"
          style={{ minWidth: "300px" }}
        >
          <div className="account-card">
            <div className="title">
              <h1>Sign In</h1>
              <Breadcrumb
                items={[
                  {
                    title: <Link to="/">Home</Link>,
                  },
                  {
                    title: "Sign In",
                  },
                ]}
              />
            </div>
            <div className="account-form">
              <Form
                name="normal_login"
                className="login-form"
                layout="vertical"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
              >
                <Form.Item
                  name="username"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                    {
                      type: "email",
                      message: "Please enter a valid email!",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password!",
                    },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
                {/* <Form.Item
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Link
                    className="login-form-forgot"
                    to="/account/forgot-password"
                  >
                    Forgot password
                  </Link>
                </Form.Item> */}

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={loading}
                  >
                    Log in
                  </Button>
                  <br />
                  Or <Link to="/account/sign-up">register now!</Link>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import Title from "../sectionTitle/title";
import titleIconWhite from "../../assets/images/titleIconWhite.png";
import AboutInfo from "./aboutInfo";
import WhyChoose from "./whyChoose";
import abouttt from "../../assets/images/abbou.jpg"
export default function AboutSection() {
  return (
    <div className="about-section section">
      <Title
        text="What is Indian Delight ?"
        img={titleIconWhite}
        desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
      />
      <div className="container">
        <AboutInfo />
        <WhyChoose />
      </div>
    </div>
  );
}

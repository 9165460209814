import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../styles/contactUs.css";
import { NavLink } from "react-router-dom";
import { BiSolidPhoneCall } from "react-icons/bi";
import { AiFillMail } from "react-icons/ai";
import { SkeletonTheme } from "react-loading-skeleton";
import { FaLocationDot } from "react-icons/fa6";
import { BsFillClockFill } from "react-icons/bs";
// import ContactUs from "../components/contact_us/contactUs";
import Title from "../components/sectionTitle/title";
import React, { useEffect } from "react";
import { useCartContext } from "../context/addToCart";
import { useArrayContext } from "../context/iconsContext";

export default function ContactUsPage() {
  const { branchData } = useCartContext();
  const { iconsList } = useArrayContext();
  useEffect(() => {
    // Scroll to the top of the page on component mount
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <SkeletonTheme baseColor="#dbdbdb99" highlightColor="white">
        <Navbar />
        <div className=" contact-page paddingtop">
          <div className="contact-banner">
            <h1>We're Just a Click Away from Delightful Dining.</h1>
          </div>

          <div className="branch-contact section" id="location">
            <Title text="JOIN US AT ANY OF OUR LOCATIONS!" />
            {branchData.map((branchItem) => (
              <div className="container d-flex justify-content-center mb-5">
                <div className="row">
                  <div
                    className={`col-md-6 ${
                      branchItem.branch_slug === "indian-delight-hattiesburg"
                        ? "order-2 order-md-1"
                        : "order-2"
                    }`}
                  >
                    <h2>{branchItem.name}</h2>
                    <div className="contact-detail">
                      <p>
                        <BiSolidPhoneCall />
                        +1 {branchItem.phone}
                      </p>
                      <p>
                        <AiFillMail />
                        {branchItem.email}
                      </p>
                    </div>
                    <div className="contact-address">
                      <FaLocationDot />
                      <p>{branchItem.address}</p>
                    </div>
                    <div className="branch-time">
                      <h3>
                        <BsFillClockFill />
                        Hours of Operation
                      </h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: branchItem.branchtime,
                        }}
                      />
                    </div>
                    <div className="social-media">
                      <ul className="footerLinks">
                        {branchData[0].social.map((valueItem) => (
                          <li>
                            <NavLink
                              alt={valueItem.name}
                              target="_blank"
                              to={valueItem.link}
                              className={valueItem.name}
                            >
                              {iconsList.map((item) =>
                                item.name === valueItem.name ? item.icon : null
                              )}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                      {/* <Link
                        className="mapLink"
                        to="https://maps.app.goo.gl/ak7u9rcMPS7NKRtG6"
                      >
                        <FaMapMarkedAlt />
                        Go to google map
                      </Link> */}
                    </div>
                  </div>
                  <div
                    className={`col-md-6 mb-3 mb-md-0${
                      branchItem.branch_slug === "indian-delight-hattiesburg"
                        ? "order-1 order-md-2"
                        : "order-1 "
                    }`}
                  >
                    <div className="branch-img">
                      <img
                        src={branchItem.branch_img}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* <ContactUs /> */}

        <Footer />
      </SkeletonTheme>
    </div>
  );
}

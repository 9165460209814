import React from "react";
import Title from "../sectionTitle/title";
import "../../styles/portfolio.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import PortfolioCard from "./PortfolioCard";
import FeaturedDishesChickenRoti from "../../assets/images/FEATURED DISHES/Featured Dishes Chicken Roti.jpg";
import FeaturedDishesChickenLollipop from "../../assets/images/FEATURED DISHES/Featured Dishes Chicken Lollipop.jpg";
import FeaturedDishes01 from "../../assets/images/FEATURED DISHES/FEATURED DISHES 01.jpg"
import FeaturedDishes02 from "../../assets/images/FEATURED DISHES/FEATURED DISHES 02.jpg"
import FeaturedDishes03 from "../../assets/images/FEATURED DISHES/FEATURED DISHES 03.jpg"
import FeaturedDishes04 from "../../assets/images/FEATURED DISHES/FEATURED DISHES 04.jpg"
import FeaturedDishes05 from "../../assets/images/FEATURED DISHES/FEATURED DISHES 05.jpg"
import FeaturedDishes06 from "../../assets/images/FEATURED DISHES/FEATURED DISHES 06.jpg"
import FeaturedDishes07 from "../../assets/images/FEATURED DISHES/FEATURED DISHES 07.jpg"
import FeaturedDishes08 from "../../assets/images/FEATURED DISHES/FEATURED DISHES 08.jpg"
import FeaturedDishes09 from "../../assets/images/FEATURED DISHES/FEATURED DISHES 09.jpg"

export default function PortfolioSection() {
  const imgList = [
    {
      img: FeaturedDishesChickenRoti,
    },
    {
      img: FeaturedDishesChickenLollipop,
    },
    {
      img: FeaturedDishes01,
    },
    {
      img: FeaturedDishes02,
    },
    {
      img: FeaturedDishes03,
    },
    {
      img: FeaturedDishes04,
    },
    {
      img: FeaturedDishes05,
    },
    {
      img: FeaturedDishes06,
    },
    {
      img: FeaturedDishes07,
    },
    {
      img: FeaturedDishes08,
    },
    {
      img: FeaturedDishes09,
    },

  ];
  return (
    <div className="portfolio-section section">
      <Title
        text="FEATURED DISHES"
        desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
       style={{color:"white"}}/>
      <div className="container">
        <Swiper
          navigation={true}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          {imgList.map((value) => (
            <SwiperSlide style={{ display: "flex", justifyContent: "center" }}>
              <PortfolioCard imgLink={value.img} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

import React from "react";
import titleIcon from "../../assets/images/titleIcon.png";

export default function Title({ text, shortTitle, img }) {
  return (
    <div className="sectionTitleData mb-2">
      <h2 className="sectionTitle">{text}</h2>
      {/* <img src={img ? img : titleIcon} /> */}
      <h4 className="sectionDesc">{shortTitle}</h4>
    </div>
  );
}

const storeToken = (value) => {

      sessionStorage.setItem("access_token", value);
    
};

const getToken = () => {

    let access_token = sessionStorage.getItem("access_token");
  
  return { access_token };
};

const removeToken = () => {
  sessionStorage.clear();
  localStorage.clear();
};

const setProfile = (value) => {
    sessionStorage.setItem("profile", JSON.stringify(value));
};

const getProfile = () => {
  let profile = "";

  if (sessionStorage.getItem("profile") !== null) {
    profile = JSON.parse(sessionStorage.getItem("profile"));
  }
  return { profile };
};

const getGuestProfile = () => {
  let guestDetail = "";

  if (sessionStorage.getItem("guestDetails") !== null) {
    guestDetail = JSON.parse(sessionStorage.getItem("guestDetails"));
  }
  return { guestDetail };
};

export { storeToken, getToken, removeToken, setProfile, getProfile, getGuestProfile };

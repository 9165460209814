import { Image } from "antd";
import React from "react";

export default function PortfolioCard({imgLink}) {
  return (
    <div>
      <Image
        width="100%"
        style={{
            height:"400px",
            objectFit:"cover"
        }}
        src={imgLink}
      />
    </div>
  );
}

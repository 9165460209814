import React, { useState } from "react";
import "../../styles/foodCard.css";
import { Popover, Select, Form } from "antd";
import "react-loading-skeleton/dist/skeleton.css";
import { useCartContext } from "../../context/addToCart";
import { FaCartPlus } from "react-icons/fa";

function FoodCard({ value, isShowPopup }) {
  const { handleAddCart } = useCartContext();
  const [spicy, setSpicy] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleQtyChange = (data) => {
    setSpicy(data);
  };

  const handAddToCartChange = () => {
    const data = {
      ...value,
      spice: spicy,
      itemQty: 1,
    };

    handleAddCart(data);
  };

  const spiceList = [
    "Very Mild",
    "Mild",
    "Medium",
    "Spicy",
    "Indian Spicy",
    "Dynamite",
  ];

  return (
    <div>
      <div className="food-card-container">
        <div className="food-card-image">
          <img src={value.Image} alt="" />
        </div>
        <div className="food-card-text">
          <h3>{value.Name}</h3>
<p>{value.Description}</p>
          <div className="food-card-button mb-3">
            <h6>$&nbsp;{value.price}</h6>
            {isShowPopup ? (
              <div
                className="add-to-cart-button-container"
                onClick={handAddToCartChange}
              >
                Add <FaCartPlus />
              </div>
            ) : (
              <Popover
                content={
                  <Form
                    onFinish={handAddToCartChange}
                    className="d-flex justify-content-between"
                  >
                    <Form.Item
                      name="spice"
                      rules={[
                        {
                          required: true,
                          message: "spice level is required",
                        },
                      ]}
                    >
                      <Select
                        style={{ minWidth: "100px" }}
                        className="select"
                        placeholder="Spicy level"
                        onChange={handleQtyChange}
                      >
                        {spiceList.map((value) => (
                          <Select.Option value={value} label={value}>
                            {value}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item>
                      <button htmlType="submit" className="okButton">
                        Add
                      </button>
                    </Form.Item>
                  </Form>
                }
                title="Select Spice Level"
                trigger="click"
                open={open}
                onOpenChange={handleOpenChange}
                // onOpenChange={handAddToCart}
              >
                <div className="add-to-cart-button-container">
                  Add <FaCartPlus />
                </div>
              </Popover>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FoodCard;

import React from "react";
import aboutUs  from "../../assets/images/download.jpeg";
import abbout from "../../assets/images/abbou.jpg"

export default function AboutInfo() {
  return (
    <div className="row">
      <div className="col-md-6 order-2 order-md-1">
        <div className="aboutContent">
          <p>
            Indian Delight is not just a restaurant; it's a culinary journey
            that invites patrons to immerse themselves in the rich tapestry of
            authentic Indian flavors. With a presence in Hettisburg and Ocean
            Spring, we've established ourselves as the go-to destination for
            those seeking the finest Indian cuisine in Mississippi.
          </p>
          <p>
            At Indian Delight, our philosophy revolves around delivering an
            unparalleled dining experience. From the warm hospitality that
            greets you at the door to the meticulously crafted menu that
            showcases the diverse and aromatic flavors of India, every aspect is
            designed to transport you to the heart of Indian culinary
            excellence. Our chefs, passionate about their craft, bring a perfect
            blend of tradition and innovation to the table, ensuring that each
            dish tells a story of authenticity and taste.
          </p>
          <p>
            Whether you're a connoisseur of Indian cuisine or a first-time
            explorer, Indian Delight is a place where the love for food and
            culture converge. Join us and discover the magic of India on a plate
            — where every bite is a celebration of flavor, tradition, and
            warmth.
          </p>
        </div>
      </div>
      <div className="col-md-6 order-1 order-md-2 mb-3 mb-md-0">
        <div className="aboutImg">
          <img
            src={abbout}
            alt="about image"
          />
        </div>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import SignIn from "../components/account/SignIn";
import SignUp from "../components/account/SignUp";
import { useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default function Account() {
  const { page } = useParams();
  useEffect(() => {
    // Scroll to the top of the page on component mount
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {page === "sign-in" || page === "sign-up" ? null : <Navbar />}
      <div className="login-page" style={{backgroundColor:"white"}}>
        <div className="container d-flex justify-content-center">
          {page === "sign-in" ? <SignIn /> : null}
          {page === "sign-up" ? <SignUp /> : null}
        </div>
      </div>
      {page === "sign-in" || page === "sign-up" ? null : <Footer />}
    </>
  );
}
